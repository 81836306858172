import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { MdArrowOutward } from "react-icons/md";
import '../css/home.css';
import Banner from '../images/banner.png';
import Back2 from '../images/background2.png';
import Header from './Header';
import Footer from './Footer';
import IMG1 from '../images/dooor.jpg';
import IMG2 from '../images/dooor2.jpg';
import IMG3 from '../images/dooor3.jpg';
import IMG4 from '../images/dooor4.jpeg';
import IMG5 from '../images/dooor5.jpeg';
import IMG6 from '../images/dooor6.jpeg';

const Home = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'items7',));
        const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching items:', error);
        setLoading(false);
      }
    };

    fetchItems();
  }, []);
  const [items2, setItems2] = useState([]);

  useEffect(() => {
    const fetchItems2 = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'items9'));
        const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems2(itemsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching items:', error);
        setLoading(false);
      }
    };

    fetchItems2();
  }, []);



    return (
       <>
       <Header/>
       <section className='my-component'>
       {items.map(item => (
            <h1 >{item.description}</h1>
        ))}
        <div className="servicesL">
           <div className="goservices test4">
            <img src={Back2} alt="" className='back2' />
            {items.map(item => (
               <p key={item.id}>{item.additionalDescription}</p>
             ))}
              <Link to={'/services'}>
              <button>Services</button>
              </Link>
           </div>
           {items2.map(item => (
              <div key={item.id}>
                  <img src={item.imageUrl} className='banner' />
              </div>
             ))}
             <img src="" alt="" />
           <div className="goservices test3">
            <img src={Back2} alt="" className='back2' />
            {items.map(item => (
            <p key={item.id}>{item.additionalDescription}</p>
             ))}
              <Link to={'/services'}>
              <button>Services</button>
              </Link>
           </div>
        </div>
       </section>
       <section className='ourProducts'>
        <h2>Our Products</h2>
        <div className="ser-cards">
          <article className='card1'>
            <div className="title">
              <h3>Exterior Doors</h3>
              <Link to={'/pageone'}>
                <MdArrowOutward />
              </Link>
            </div>
           <img src={IMG1} alt="door" />
          </article>
          <article className='card2'>
            <div className="title">
              <h3>Interior Doors</h3>
              <Link to={'/pagetwo'}>
                <MdArrowOutward />
              </Link>
            </div>
           <img src={IMG2} alt="door" />
          </article>
          <article className='card3'>
            <div className="title">
              <h3>Wooden Doors</h3>
              <Link to={'/pagethree'}>
                <MdArrowOutward />
              </Link>
            </div>
           <img src={IMG3} alt="door" />
          </article>
        </div>
       </section>
       <section className='ourProducts'>
        <h2>Our experience</h2>
        <div className="ser-cards ">
          <article className='test1'>
            <img src={IMG4} alt="" />
          </article>
          <article className='test1'>
            <img src={IMG5} alt="" />
          </article>
          <article className='test1'>
            <img src={IMG6} alt="" />
          </article>
        </div>
        <Link to={'/experience'} className='pro-b'>Learn More</Link>
       </section>
       <Footer/>
    </>
    
  )
}

export default Home;


