import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const AdminPanel4 = () => {
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [items, setItems] = useState([]);

  // Fetch items on mount
  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'items4'));
      const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsData);
    };

    fetchItems();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Generate a unique ID for the document
    const id = uuidv4();

    // Upload image to Firebase Storage
    const fileName = id;
    const storageRef = ref(getStorage(), `images/${fileName}`);
    await uploadBytes(storageRef, image);

    // Get the download URL
    const imageUrl = await getDownloadURL(storageRef);

    // Save data to Firestore
    await setDoc(doc(db, 'items4', fileName), { imageUrl, description }); // Include description in the document

    // Fetch items again to update list
    const querySnapshot = await getDocs(collection(db, 'items4'));
    const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setItems(itemsData);

    setImage(null);
    setDescription('');
  };

  const handleRemove = async (id) => {
    await deleteDoc(doc(db, 'items4', id));

    const querySnapshot = await getDocs(collection(db, 'items4'));
    const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setItems(itemsData);
  };

  return (
    <div className='admin'>
      <div className='ourProducts'>
        <h2>Gallery</h2>
      </div>
      <form onSubmit={handleSubmit} className='d-flex'>
        <input type="file" onChange={handleImageChange} />
        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" /> {/* Add input for description */}
        <button type="submit">Upload</button>
      </form>
      <div className='d-flex'>
        {items.map(item => (
          <div key={item.id} className='flexx'>
            <img src={item.imageUrl} alt="item" />
            <p>{item.description}</p>
            <button onClick={() => handleRemove(item.id)}>Remove</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPanel4;
