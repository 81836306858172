import React, { useEffect, useState } from 'react';
import Logo from '../images/logo.svg'
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import '../css/home.css'
import'../css/responsive.css'
import BurgerMenu from './BurgerMenu';
import { FaPhoneAlt } from "react-icons/fa";


const Footer = () => {

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'items6'));
      if (querySnapshot.empty) {
        setLoading(false);
        return;
      }

      const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsData);
      setLoading(false);
    };

    fetchItems();
  }, []);


  return (
    <footer>
    <div className='part-1'>
    {items.map(item => (
            <img className='fotlogo' src={item.imageUrl} alt={item.title} />
        ))}
      <div className='navigate'>
        <div className="menu1">
        <Link to="/">
        <p>Home</p>
        </Link>
        <Link to="/about">
        <p>About Us</p>
        </Link>
        <Link to="/contact">
        <p>Contact</p>
        </Link>

        </div>
        <div className="menu2">
            <Link to={'/services'}>
                <p>Services</p>
            </Link>
            <Link to={'/login'}>
            <p>Admin</p>
            </Link>
            <Link to={'/experience'}>
            <p>Our Gallery</p>
            </Link>
        </div>
      </div>
    </div>
    <div className="bordermid"></div>
    <div className="part-2">
      <FaFacebookF className='blue' />
      <FaGoogle className='yello' />
      <FaPhone  className='green'/>
    </div>
   </footer>
  )
}

export default Footer