import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Header from './Header';
import Footer from './Footer';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_i2z3547', 'template_9aefwaz', form.current, {
        publicKey: 'Q99QM6E75rnL23je1',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          // Reload the page after the email is sent successfully
          window.location.reload();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <> 
    <Header/>
    <div className='d-flex extra'>
     <div className='get-t'>
       <h1>
        Your feedback
        helps us improve
      </h1>
       <p>
        We are here to help you and we'd love to connect with you.
        We'll get back in 24h
        </p>
     </div>
      <form ref={form} onSubmit={sendEmail} className='email'>
      <label>Name</label>
      <input type="text" name="user_name" placeholder='Enter Name' />
      <label>Email</label>
      <input type="email" name="user_email" placeholder='Enter Email' />
      <label>Message</label>
      <textarea name="message" placeholder='Enter Message' />
      <button type="submit" value="Send">Send</button>
    </form>
    </div>
    <Footer/>
    </>
  )
}

export default Contact