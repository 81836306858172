import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import '../css/home.css'
import'../css/responsive.css'
import BurgerMenu from './BurgerMenu';
import { FaPhoneAlt } from "react-icons/fa";



const Header = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'items6'));
      if (querySnapshot.empty) {
        setLoading(false);
        return;
      }

      const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsData);
      setLoading(false);
    };

    fetchItems();
  }, []);
   
  const [description, setDescription] = useState('');
  const [items10, setItems10] = useState([]);

  // Fetch items
  const fetchItems = async () => {
    const querySnapshot = await getDocs(collection(db, 'items10'));
    const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return itemsData;
  };

  useEffect(() => {
    const loadItems = async () => {
      const itemsData = await fetchItems();
      setItems10(itemsData);
    };
    loadItems();
  }, []);

  

  return (
    <header>
      <div className='halfhead'>
        <Link className='ddd' to="/">           
          <div className="logo1 ">
          <div className='flexx'>
          {items.map(item => (
          <div key={item.id} >
            <img src={item.imageUrl} alt={item.title} />
          </div>
        ))}
          </div>
          </div>
        </Link>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact Us</Link>
        </nav>
      </div>
      <div className="number">
      {items10.map(item => (
        <div className="number">
          <FaPhoneAlt />
          <p>{item.description}</p>
        </div>
        ))}
      </div>
      <BurgerMenu/>
    </header>
  );
};

export default Header;
