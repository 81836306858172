import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Header from './Header';
import Footer from './Footer';

const About = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'items8'));
        const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching items:', error);
        setLoading(false);
      }
    };

    fetchItems();
  }, []);




  return (
    <>
       <Header/>
       <section className='about-us'>
        {items.map(item => (
        <div key={item.id} >
            <h4>{item.description}</h4>
            <p>{item.additionalDescription}</p>
        </div>
        ))}

       </section>
       <Footer/>
    </>
  )
}

export default About