import React, { useState, useEffect } from 'react';
import { deleteDoc, getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const AdminPanel8 = () => {
  const [description, setDescription] = useState('');
  const [additionalDescription, setAdditionalDescription] = useState('');
  const [items, setItems] = useState([]);

  // Fetch items
  const fetchItems = async () => {
    const querySnapshot = await getDocs(collection(db, 'items8'));
    const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return itemsData;
  };

  useEffect(() => {
    const loadItems = async () => {
      const itemsData = await fetchItems();
      setItems(itemsData);
    };
    loadItems();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = uuidv4();
    await setDoc(doc(db, 'items8', id), { description, additionalDescription });
    setDescription('');
    setAdditionalDescription('');
    const updatedItems = await fetchItems();
    setItems(updatedItems);
  };

  const handleRemove = async (id) => {
    await deleteDoc(doc(db, 'items8', id));
    const updatedItems = items.filter(item => item.id !== id);
    setItems(updatedItems);
  };

  return (
    <div className='admin'>
      <div className='ourProducts'>
        <h2>About</h2>
      </div>
      <form onSubmit={handleSubmit} className='d-flex'>
        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Title" />
        <input type="text" value={additionalDescription} onChange={(e) => setAdditionalDescription(e.target.value)} placeholder="Description" />
        <button type="submit">Upload</button>
      </form>
      <div className='d-flex'>
        {items.map(item => (
          <div key={item.id} className='flexx'>
            <p>{item.description}</p>
            <p>{item.additionalDescription}</p>
            <button onClick={() => handleRemove(item.id)}>Remove</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPanel8;

