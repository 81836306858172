import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your authentication logic here
    if (username === 'door' && password === 'dforlife.pp') {
      console.log('Authentication successful');
      navigate('/adminpanel19277');
    } else {
      console.error('Invalid username or password');
    }
  };

  return (
    <div className='login'>
      <form onSubmit={handleSubmit} className='email'>
      <h1>Login to AdminPanel</h1>
        <input
          type="text"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className='ee'
        />
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default LoginPage;


