import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About'
import Contact from './pages/Contact'
import AdminPanel from '../src/pages/AdminPanel'
import PageOne from './pages/Page1';
import PageTwo from './pages/Page2';
import PageThree from './pages/Page3';
import PageFour from './pages/Experience';
import PageFive from './pages/Services';
import LoginPage from './pages/LoginPage'; // Import your LoginPage component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<LoginPage />} /> // Add this line
        <Route path="/adminpanel19277" element={<AdminPanel />} />
        <Route path="/pageone" element={<PageOne />} />
        <Route path="/pagetwo" element={<PageTwo />} />
        <Route path="/pagethree" element={<PageThree />} />
        <Route path="/experience" element={<PageFour />} />
        <Route path="/services" element={<PageFive />} />
      </Routes>
    </Router>
  );
}

export default App;

