import React, { useState, useEffect } from 'react';
import { deleteDoc, getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const AdminPanel7 = () => {
  const [description, setDescription] = useState('');
  const [additionalDescription, setAdditionalDescription] = useState('');
  const [items, setItems] = useState([]);
  const [descriptionItems, setDescriptionItems] = useState([]);
  const [additionalDescriptionItems, setAdditionalDescriptionItems] = useState([]);

  // Fetch items on mount
  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'items7'));
      const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsData);
      const descItems = itemsData.filter(item => item.description);
      const addDescItems = itemsData.filter(item => item.additionalDescription);
      setDescriptionItems(descItems);
      setAdditionalDescriptionItems(addDescItems);
    };

    fetchItems();
  }, []);

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    const id = uuidv4();
    if (type === 'description') {
      await setDoc(doc(db, 'items7', id), { description });
      setDescription('');
      const updatedItems = await fetchItems();
      setDescriptionItems(updatedItems.filter(item => item.description));
    } else if (type === 'additionalDescription') {
      await setDoc(doc(db, 'items7', id), { additionalDescription });
      setAdditionalDescription('');
      const updatedItems = await fetchItems();
      setAdditionalDescriptionItems(updatedItems.filter(item => item.additionalDescription));
    }
  };

  const fetchItems = async () => {
    const querySnapshot = await getDocs(collection(db, 'items7'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const handleRemove = async (id, type) => {
    await deleteDoc(doc(db, 'items7', id));
    const updatedItems = items.filter(item => item.id !== id);
    setItems(updatedItems);
    if (type === 'description') {
      const updatedDescItems = descriptionItems.filter(item => item.id !== id);
      setDescriptionItems(updatedDescItems);
    } else if (type === 'additionalDescription') {
      const updatedAddDescItems = additionalDescriptionItems.filter(item => item.id !== id);
      setAdditionalDescriptionItems(updatedAddDescItems);
    }
  };

  return (
    <div className='admin'>
      <div className='ourProducts'>
        <h2>Banners</h2>
      </div>
      <form onSubmit={(e) => handleSubmit(e, 'description')} className='d-flex'>
        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        <button type="submit">Upload Description</button>
      </form>
      <form onSubmit={(e) => handleSubmit(e, 'additionalDescription')} className='d-flex'>
        <input type="text" value={additionalDescription} onChange={(e) => setAdditionalDescription(e.target.value)} placeholder="Additional Description" />
        <button type="submit">Upload Additional Description</button>
      </form>
      <div className='d-flex'>
        {descriptionItems.map(item => (
          <div key={item.id} className='flexx'>
            <p>{item.description}</p>
            <button onClick={() => handleRemove(item.id, 'description')}>Remove Description</button>
          </div>
        ))}
        {additionalDescriptionItems.map(item => (
          <div key={item.id} className='flexx'>
            <p>{item.additionalDescription}</p>
            <button onClick={() => handleRemove(item.id, 'additionalDescription')}>Remove Additional Description</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPanel7;





