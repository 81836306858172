import React, { useState } from "react";
import { Menu} from "burger-menu";
import { Link } from 'react-router-dom';
import 'burger-menu/lib/index.css';
import { IoCall } from "react-icons/io5";
import { TiThMenu } from "react-icons/ti";

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div onClick={() => setIsOpen(!isOpen)}><TiThMenu  className="m-icon" /></div>
      <Menu className="burger-menu" isOpen={isOpen} selectedKey={'entry'} onClose={() => setIsOpen(false)}>
      <Link to="/">Home</Link>
      <Link to="/about">About Us</Link>
       <Link to="/contact">Contact Us</Link>
       <div className="number">
    <IoCall />
      <p>(+88) 76654 4701</p>
    </div>
      </Menu>
    </>
  )
};
export default BurgerMenu

