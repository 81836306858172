import React from 'react'
import AdminPanel1 from './Admin1'
import AdminPanel2 from './Admin2'
import AdminPanel3 from './Admin3'
import '../css/admin.css'
import AdminPanel4 from './Admin4'
import AdminPanel5 from './Admin5'
import AdminPanel6 from './Admin6'
import AdminPanel7 from './Admin7'
import AdminPanel8 from './Admin8'
import AdminPanel9 from './Admin9'
import AdminPanel10 from './Admin10'

const AdminPanel = () => {
  return (
    <div>
      <h1 className='adminh1'>Admin Panel</h1>
      <AdminPanel1/>
      <AdminPanel2/>
      <AdminPanel3/>
      <AdminPanel4/>
      <AdminPanel5/>
      <AdminPanel6/>
      <AdminPanel7/>
      <AdminPanel9/>
      <AdminPanel8/>
      <AdminPanel10/>

    </div>
  )
}

export default AdminPanel