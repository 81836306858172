import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import '../css/home.css'
import Header from './Header';
import Footer from './Footer';


const PageThree = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'items3'));
      if (querySnapshot.empty) {
        setLoading(false);
        return;
      }

      const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsData);
      setLoading(false);
    };

    fetchItems();
  }, []);

  return (
    <>
      <Header/>
      {loading && <p>Loading...</p>}
      {!loading && items.length === 0 && <p>No items to display</p>}
      <div className='ourProducts'>
        <h2>Wooden Doors</h2>
      </div>
      <div className='gallery'>
        {items.map(item => (
          <div key={item.id} >
            <img src={item.imageUrl} alt={item.title} />
          </div>
        ))}
      </div>
      <Footer/>
    </>
  );

};

export default PageThree;

