import React, { useState, useEffect } from 'react';
import { deleteDoc, getDocs, collection } from 'firebase/firestore';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const AdminPanel10 = () => {
  const [description, setDescription] = useState('');
  const [items10, setItems10] = useState([]);

  // Fetch items
  const fetchItems = async () => {
    const querySnapshot = await getDocs(collection(db, 'items10'));
    const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return itemsData;
  };

  useEffect(() => {
    const loadItems = async () => {
      const itemsData = await fetchItems();
      setItems10(itemsData);
    };
    loadItems();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = uuidv4();
    await setDoc(doc(db, 'items10', id), { description });
    setDescription('');
    const updatedItems = await fetchItems();
    setItems10(updatedItems);
  };

  const handleRemove = async (id) => {
    await deleteDoc(doc(db, 'items10', id));
    const updatedItems = items10.filter(item => item.id !== id);
    setItems10(updatedItems);
  };

  return (
    <div className='admin'>
      <div className='ourProducts'>
        <h2>Phone number</h2>
      </div>
      <form onSubmit={handleSubmit} className='d-flex'>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
        <button type="submit">Upload</button>
      </form>
      <div className='d-flex'>
        {items10.map(item => (
          <div key={item.id} className='flexx'>
            <p>{item.description}</p>
            <button onClick={() => handleRemove(item.id)}>Remove</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPanel10;

